import React from 'react';
import Layout from '../components/layout/Layout';

const NotFound = () => (
  <Layout>
    <h1>Nicht gefunden</h1>
    <p>Diese Seite existiert nicht.</p>
  </Layout>
);

export default NotFound;
